<template>
  <div class="privacy-main">
    <div class="verification">
      <div class="row bootstrap snippets bootdeys m-0">
        <div
          class="col-12 col-sm-12 col-md-10 col-lg-12 col-xl-6 col-md-offset-3"
        >
          <div
            class="m-t-30 card-box"
            style="
              padding: 20px;"
          >
            <div class="panel-body text-center">
              <div class="svg___icon">
                <img src="@/assets/images/icons/verify-icon.svg" alt="" />
              </div>
              <div class="verification-title">
                <h2>Verify your mailbox</h2>
                <p class="sub-title font-13 m-t-20">
                  You will need to verify your email <b>{{ user_email }}</b> to
                  complete registration
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { USER_EMAIL } from "../../store/constant/actions.type";
export default {
  name: "verification",
  data() {
    return {
      user_email:
        this.$store.state.auth && this.$store.state.auth.email
          ? this.$store.state.auth.email
          : localStorage.getItem(USER_EMAIL),
    };
  },
};
</script>
<style scoped>
body {
  margin-top: 20px;
}
.card-box .card-drop {
  color: #9a9da0;
  font-size: 20px;
  line-height: 1px;
  padding: 0px 5px;
  display: inline-block;
}
.verification .row {
  justify-content: center;
  width: 100%;
  height: 800px;
  align-items: center;
}
.verification .panel-body {
  display: flex;
  flex-direction: column;
  gap: 65px;
}
.verification .verification-title h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 48px;
  text-align: center;
  color: #10234c;
  margin: 0;
}
.verification .verification-title .sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #10234c;
}
@media screen and (max-width: 767px) {
  .verification .verification-title h2 {
    font-size: 24px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  .verification .verification-title .sub-title {
    font-size: 14px;
    line-height: 24px;
  }
}
</style>
