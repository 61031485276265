<template>
  <div class="sign-in-main">
    <authHeader />
    <div class="main-body">
      <div class="login-form">
        <div class="row">
          <div class="col-md-6 px-0">
            <div class="login-detail">
              <form @submit.prevent="onSubmit">
                <div class="heading-login">
                  <h3>Forgot password</h3>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Email address</label>
                    <input
                      type="email"
                      v-model="user.email"
                      name="email"
                      placeholder="Enter your email"
                      class="form-control"
                      :disabled="inputBoxShow"
                      id="email"
                      :class="{
                        'is-invalid': submitted && $v.user.email.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.user.email.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.user.email.required"
                        >Please enter a valid corporate email</span
                      >
                      <span v-if="!$v.user.email.email">Email is invalid</span>
                    </div>
                  </div>
                </div>
                <div v-if="show">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>OTP</label>
                      <input
                        type="otp"
                        v-model="userForgotpassword.otp"
                        name="otp"
                        class="form-control"
                        placeholder="Enter your OTP"
                        id="otp"
                        :class="{
                          'is-invalid':
                            showValidations &&
                            submitted &&
                            $v.userForgotpassword.otp.$error,
                        }"
                      />
                      <div
                        v-if="
                          showValidations &&
                            submitted &&
                            $v.userForgotpassword.otp.$error
                        "
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.userForgotpassword.otp.required"
                          >Please enter a otp</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Password</label>
                      <input
                        type="password"
                        v-model="userForgotpassword.password"
                        name="password"
                        class="form-control"
                        placeholder="Enter your Password"
                        id="password"
                        :class="{
                          'is-invalid':
                            showValidations &&
                            submitted &&
                            $v.userForgotpassword.password.$error,
                        }"
                      />
                      <div
                        v-if="
                          showValidations &&
                            submitted &&
                            $v.userForgotpassword.password.$error
                        "
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.userForgotpassword.password.required"
                          >Please enter a password</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Confirm Password</label>
                      <input
                        type="password"
                        name="confirmPassword"
                        v-model="userForgotpassword.confirmPassword"
                        placeholder="Enter your Confirm Password"
                        class="form-control"
                        id="confirmPassword"
                        :class="{
                          'is-invalid':
                            showValidations &&
                            submitted &&
                            $v.userForgotpassword.confirmPassword.$error,
                        }"
                      />
                      <div
                        v-if="
                          showValidations &&
                            submitted &&
                            $v.userForgotpassword.confirmPassword.$invalid
                        "
                        class="invalid-feedback"
                      >
                        <span
                          v-if="!$v.userForgotpassword.confirmPassword.required"
                          >Please enter a confirm password</span
                        >
                        <span
                          v-else-if="
                            !$v.userForgotpassword.confirmPassword
                              .sameAsPassword
                          "
                          >Password do not match</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sign-btn">
                  <router-link
                    :to="{ name: 'signin' }"
                    class="secondary-text font-lg-12 weight-600"
                  >
                    Sign in?</router-link
                  >
                </div>
                <div class="text-center sign-btn mt-4">
                  <button type="submit" class="btn secondary-btn btn-block">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-6 px-0 desktop-screen bg-shape">
            <div class="right-bar">
              <b-carousel
                id="carousel-1"
                v-model="slide"
                :interval="4000"
                controls
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
              >
                <!-- Slides with custom text -->
                <b-carousel-slide img-blank img-alt="Blank image">
                  <div class="slider-main">
                    <h3>Live Data</h3>
                    <p class="font-lg-14">
                      All data present in Datagma is collected live and is never
                      stored to guarantee the freshness of our data and GDPR
                      compliance.
                    </p>
                  </div>
                </b-carousel-slide>
                <b-carousel-slide img-blank img-alt="Blank image">
                  <div class="slider-main">
                    <h3>Email Verified</h3>
                    <p class="font-lg-14">
                      All Datagma emails are verified, so you don't have to
                      recheck them later with another tool.
                    </p>
                  </div>
                </b-carousel-slide>
                <b-carousel-slide img-blank img-alt="Blank image">
                  <div class="slider-main">
                    <h3>Catch-All Detection</h3>
                    <p class="font-lg-14">
                      When we are in front of a catch-all email, our robots
                      still try to find if the email exists on the web to
                      validate it. These are the emails with the status
                      catch-all but found on the web.
                    </p>
                  </div>
                </b-carousel-slide>
                <b-carousel-slide img-blank img-alt="Blank image">
                  <div class="slider-main">
                    <h3>Job Change Detection</h3>
                    <p class="font-lg-14">
                      Thanks to our technology, we can detect when a target
                      changes job position and inform you in almost real-time.
                    </p>
                  </div>
                </b-carousel-slide>
              </b-carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { required, email, sameAs } from "vuelidate/lib/validators";
import {
  FORGOT_PASSWORD,
  VERIFY_FORGOT_PASSWORD,
} from "../../store/constant/actions.type";
import { loadingService } from "../../main/common/services/loading.service";
export default {
  components: {
    authHeader: () => import("../../main/common/auth_header/auth-header.vue"),
  },
  data() {
    return {
      user: {
        email: null,
      },
      userForgotpassword: {
        otp: null,
        password: null,
        confirmPassword: null,
      },
      showValidations: false,
      show: false,
      inputBoxShow: false,
      fullPage: false,
      slide: 0,
      sliding: null,
      submitted: false,
    };
  },
  validations: {
    user: {
      email: { required, email },
    },
    userForgotpassword: {
      password: { required },
      otp: { required },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
    },
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    async onSubmit() {
      this.submitted = true;
      if (
        this.user.email === "" ||
        this.user.email === null ||
        this.user.email === "null" ||
        (this.user.email === undefined && this.$v.user.email.$error)
      ) {
        this.$v.$touch();
        return;
      } else if (
        this.userForgotpassword.otp === "null" ||
        (this.userForgotpassword.otp === null && !this.inputBoxShow)
      ) {
        loadingService.setloader(true);
        await this.$store
          .dispatch(FORGOT_PASSWORD, this.user)
          .then((response) => {
            if (response.sentEmail) {
              loadingService.setloader(false);
              this.show = true;
              this.inputBoxShow = true;
              this.flashMessage.success({
                title: "Success",
                message: `SuccessFully OTP sent in an ${this.user.email}`,
              });
            }
          })
          .catch((err) => {
            loadingService.setloader(false);
            if (err) {
              this.flashMessage.error({
                title: "Error Message",
                message: err.message,
              });
            }
          });
      } else {
        if (
          this.$v.$invalid ||
          this.userForgotpassword.otp === "null" ||
          (this.userForgotpassword.otp === null && this.inputBoxShow)
        ) {
          this.$v.$touch();
          this.showValidations = true;
          return;
        } else {
          loadingService.setloader(false);
          await this.$store
            .dispatch(VERIFY_FORGOT_PASSWORD, this.userForgotpassword)
            .then((response) => {
              if (response.success) {
                this.flashMessage.success({
                  title: "Success",
                  message: "SuccessFully Password Changed",
                });
                this.$router.push({ name: "signin" });
              }
            })
            .catch((err) => {
              if (err) {
                loadingService.setloader(false);
                this.flashMessage.error({
                  title: "Error Message",
                  message: err.message,
                });
              }
            });
        }
      }
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
};
</script>
<style scoped>
.sign-in-header {
  display: flex;
  justify-content: space-between;
  padding: 15.5px 32px;
}
.heading-login h3 {
  padding-bottom: 16px;
}
.main-body {
  background: #e5e5e5;
  background-repeat: no-repeat;
  height: calc(100vh - 74.8px);
  overflow: auto;
  padding: 64px 15px 15px 15px;
}
/* 
.logo img {
  max-width: 50px;
} */

.login-form {
  height: 616px;
  color: white;
  overflow: hidden;
  max-width: 1126px;
  margin: 0 auto;
  width: 100%;
  box-shadow: 0px 34px 80px rgb(0 0 0 / 7%);
  position: relative;
}

.login-detail {
  background-color: #fff;
  width: 100%;
  z-index: 2;
  height: 616px;
  position: relative;
  padding: 0 15px;
}
.login-detail form {
  max-width: 307px;
  margin: 0 auto;
  padding-top: 27px;
}
.right-bar {
  position: relative;
  display: grid;
  place-content: center;
  height: 100%;
  z-index: 1;
}
.right-bar:before {
  background-color: rgb(255 255 255 / 12%);
  position: absolute;
  width: 200px;
  height: 200px;
  left: 306px;
  top: 102px;
  border-radius: 50%;
  z-index: -1;
  content: "";
}
.carousel {
  width: 480px;
  min-height: 500px;
  height: auto;
}

.bg-shape {
  position: relative;
  background-color: #003172;
}
.bg-shape:before {
  content: "";
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  right: 0;
  left: -13px;
  content: "";
  position: absolute;
  bottom: 0;
  background-position: 100% 100%;
  background-size: contain;
  background-image: url("../../../assets/images/icons/login-bg.png");
}
.slider-main {
  width: 339px;
  background: #fff;
  padding: 49px 32px;
  box-shadow: 0px 34px 80px rgb(0 0 0 / 7%);
  border-radius: 4px;
  text-align: initial;
}
@media only screen and (max-width: 769px) {
  .slider-main {
    width: 250px;
    padding: 29px 20px;
  }
  .carousel {
    width: 370px;
    min-height: 270px;
    height: auto;
  }
}
@media only screen and (max-width: 576px) {
  .main-body {
    padding: 34px 15px 15px 15px;
  }
  .sign-in-header {
    padding: 15.5px 15px;
  }
  .login-form {
    height: 566px;
  }
}
</style>
