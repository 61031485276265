var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sign-in-main"},[_c('authHeader'),_c('div',{staticClass:"main-body"},[_c('div',{staticClass:"login-form"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 px-0"},[_c('div',{staticClass:"login-detail"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_vm._m(0),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Email address")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.submitted && _vm.$v.user.email.$error,
                    },attrs:{"type":"email","name":"email","placeholder":"Enter your email","disabled":_vm.inputBoxShow,"id":"email"},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "email", $event.target.value)}}}),(_vm.submitted && _vm.$v.user.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.email.required)?_c('span',[_vm._v("Please enter a valid corporate email")]):_vm._e(),(!_vm.$v.user.email.email)?_c('span',[_vm._v("Email is invalid")]):_vm._e()]):_vm._e()])]),(_vm.show)?_c('div',[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("OTP")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userForgotpassword.otp),expression:"userForgotpassword.otp"}],staticClass:"form-control",class:{
                        'is-invalid':
                          _vm.showValidations &&
                          _vm.submitted &&
                          _vm.$v.userForgotpassword.otp.$error,
                      },attrs:{"type":"otp","name":"otp","placeholder":"Enter your OTP","id":"otp"},domProps:{"value":(_vm.userForgotpassword.otp)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userForgotpassword, "otp", $event.target.value)}}}),(
                        _vm.showValidations &&
                          _vm.submitted &&
                          _vm.$v.userForgotpassword.otp.$error
                      )?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.userForgotpassword.otp.required)?_c('span',[_vm._v("Please enter a otp")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userForgotpassword.password),expression:"userForgotpassword.password"}],staticClass:"form-control",class:{
                        'is-invalid':
                          _vm.showValidations &&
                          _vm.submitted &&
                          _vm.$v.userForgotpassword.password.$error,
                      },attrs:{"type":"password","name":"password","placeholder":"Enter your Password","id":"password"},domProps:{"value":(_vm.userForgotpassword.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userForgotpassword, "password", $event.target.value)}}}),(
                        _vm.showValidations &&
                          _vm.submitted &&
                          _vm.$v.userForgotpassword.password.$error
                      )?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.userForgotpassword.password.required)?_c('span',[_vm._v("Please enter a password")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Confirm Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userForgotpassword.confirmPassword),expression:"userForgotpassword.confirmPassword"}],staticClass:"form-control",class:{
                        'is-invalid':
                          _vm.showValidations &&
                          _vm.submitted &&
                          _vm.$v.userForgotpassword.confirmPassword.$error,
                      },attrs:{"type":"password","name":"confirmPassword","placeholder":"Enter your Confirm Password","id":"confirmPassword"},domProps:{"value":(_vm.userForgotpassword.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userForgotpassword, "confirmPassword", $event.target.value)}}}),(
                        _vm.showValidations &&
                          _vm.submitted &&
                          _vm.$v.userForgotpassword.confirmPassword.$invalid
                      )?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.userForgotpassword.confirmPassword.required)?_c('span',[_vm._v("Please enter a confirm password")]):(
                          !_vm.$v.userForgotpassword.confirmPassword
                            .sameAsPassword
                        )?_c('span',[_vm._v("Password do not match")]):_vm._e()]):_vm._e()])])]):_vm._e(),_c('div',{staticClass:"sign-btn"},[_c('router-link',{staticClass:"secondary-text font-lg-12 weight-600",attrs:{"to":{ name: 'signin' }}},[_vm._v(" Sign in?")])],1),_vm._m(1)])])]),_c('div',{staticClass:"col-md-6 px-0 desktop-screen bg-shape"},[_c('div',{staticClass:"right-bar"},[_c('b-carousel',{attrs:{"id":"carousel-1","interval":4000,"controls":""},on:{"sliding-start":_vm.onSlideStart,"sliding-end":_vm.onSlideEnd},model:{value:(_vm.slide),callback:function ($$v) {_vm.slide=$$v},expression:"slide"}},[_c('b-carousel-slide',{attrs:{"img-blank":"","img-alt":"Blank image"}},[_c('div',{staticClass:"slider-main"},[_c('h3',[_vm._v("Live Data")]),_c('p',{staticClass:"font-lg-14"},[_vm._v(" All data present in Datagma is collected live and is never stored to guarantee the freshness of our data and GDPR compliance. ")])])]),_c('b-carousel-slide',{attrs:{"img-blank":"","img-alt":"Blank image"}},[_c('div',{staticClass:"slider-main"},[_c('h3',[_vm._v("Email Verified")]),_c('p',{staticClass:"font-lg-14"},[_vm._v(" All Datagma emails are verified, so you don't have to recheck them later with another tool. ")])])]),_c('b-carousel-slide',{attrs:{"img-blank":"","img-alt":"Blank image"}},[_c('div',{staticClass:"slider-main"},[_c('h3',[_vm._v("Catch-All Detection")]),_c('p',{staticClass:"font-lg-14"},[_vm._v(" When we are in front of a catch-all email, our robots still try to find if the email exists on the web to validate it. These are the emails with the status catch-all but found on the web. ")])])]),_c('b-carousel-slide',{attrs:{"img-blank":"","img-alt":"Blank image"}},[_c('div',{staticClass:"slider-main"},[_c('h3',[_vm._v("Job Change Detection")]),_c('p',{staticClass:"font-lg-14"},[_vm._v(" Thanks to our technology, we can detect when a target changes job position and inform you in almost real-time. ")])])])],1)],1)])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"heading-login"},[_c('h3',[_vm._v("Forgot password")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center sign-btn mt-4"},[_c('button',{staticClass:"btn secondary-btn btn-block",attrs:{"type":"submit"}},[_vm._v(" Submit ")])])}]

export { render, staticRenderFns }