<template>
  <div class="sign-in-main">
    <authHeader />
    <div class="main-body">
      <div class="login-form">
        <div class="row">
          <div class="col-md-6 px-0">
            <div class="login-detail">
              <form @submit.prevent="onSubmit">
                <div class="heading-login">
                  <h3>Sign In</h3>
                </div>
                <div class="form-group">
                  <label>Email address</label>
                  <input
                    type="email"
                    v-model="user.email"
                    name="email"
                    placeholder="Enter your email"
                    class="form-control"
                    id="email"
                    :class="{
                      'is-invalid': submitted && $v.user.email.$error,
                    }"
                  />
                  <div
                    v-if="submitted && $v.user.email.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.user.email.required"
                      >Please enter a valid corporate email</span
                    >
                    <span v-if="!$v.user.email.email">Email is invalid</span>
                  </div>
                </div>

                <div class="form-group">
                  <label>Password</label>
                  <input
                    :type="passwordVisible ? 'text' : 'password'"
                    v-model="user.password"
                    name="password"
                    class="form-control"
                    placeholder="Enter your password"
                    id="password"
                    :class="{
                      'is-invalid': submitted && $v.user.password.$error,
                    }"
                    autocomplete="off"
                  />
                  <div class="input-group-append">
                    <div @click="togglePasswordVisibility" class="eyebtns">
                      <div>
                        <img
                          v-if="passwordVisible"
                          src="@/assets/images/eye-disable.svg"
                          alt="Eye Open"
                        />
                        <img
                          v-else
                          src="@/assets/images/eye.svg"
                          alt="Eye Closed"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="submitted && $v.user.password.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.user.password.required"
                      >Please enter a valid password</span
                    >
                  </div>
                </div>
                <div class="sign-btn">
                  <router-link
                    :to="{ name: 'forgotPassword' }"
                    class="secondary-text font-lg-12 weight-600"
                  >
                    Forgot password?</router-link
                  >
                  <!-- <a href="#" class="secondary-text font-lg-12 weight-600"
                    >Forgot password?</a
                  > -->
                </div>
                <div class="text-center sign-btn mt-4">
                  <button type="submit" class="btn secondary-btn btn-block">
                    Log In
                  </button>
                </div>
                <div class="text-center not-have-account mt-3">
                  <span class="font-lg-12">
                    <span class="light-text">Don’t have account?</span>
                    <router-link
                      :to="{ name: 'signup' }"
                      class="secondary-text font-lg-12 weight-600"
                    >
                      Sign up</router-link
                    >
                  </span>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-6 px-0 desktop-screen bg-shape">
            <div class="right-bar">
              <b-carousel
                id="carousel-1"
                v-model="slide"
                :interval="4000"
                controls
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
              >
                <!-- Slides with custom text -->
                <b-carousel-slide img-blank img-alt="Blank image">
                  <div class="slider-main">
                    <h3>Live Data</h3>
                    <p class="font-lg-14">
                      All data present in Datagma is collected live and is never
                      stored to guarantee the freshness of our data and GDPR
                      compliance.
                    </p>
                  </div>
                </b-carousel-slide>
                <b-carousel-slide img-blank img-alt="Blank image">
                  <div class="slider-main">
                    <h3>Email Verified</h3>
                    <p class="font-lg-14">
                      All Datagma emails are verified, so you don't have to
                      recheck them later with another tool.
                    </p>
                  </div>
                </b-carousel-slide>
                <b-carousel-slide img-blank img-alt="Blank image">
                  <div class="slider-main">
                    <h3>Catch-All Detection</h3>
                    <p class="font-lg-14">
                      When we are in front of a catch-all email, our robots
                      still try to find if the email exists on the web to
                      validate it. These are the emails with the status
                      catch-all but found on the web.
                    </p>
                  </div>
                </b-carousel-slide>
                <b-carousel-slide img-blank img-alt="Blank image">
                  <div class="slider-main">
                    <h3>Job Change Detection</h3>
                    <p class="font-lg-14">
                      Thanks to our technology, we can detect when a target
                      changes job position and inform you in almost real-time.
                    </p>
                  </div>
                </b-carousel-slide>
              </b-carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  LOG_IN,
  USER_EMAIL,
  GET_USER_API_ID,
  GET_USER_CREDIT,
  // USER_CREDIT,
  GET_USER_DETAIL,
  SIGN_UP_CODE,
  FIRST_TIME,
  GET_WHATSAPP_PROTO,
} from "../../store/constant/actions.type";
import {
  REDIRECT_CODE,
  REDIRECT_INTEGRATION_NAME,
} from "../../store/constant/mutations.type";
import UtilsService from "../../main/common/services/utils.services";
// import { createLog } from "../../main/common/log_rocket/log-rocket-services";
import { required, email } from "vuelidate/lib/validators";
import { analyticsService } from "../../main/common/services/analytics.service";
import { loadingService } from "../../main/common/services/loading.service";
export default {
  name: "signin",
  components: {
    authHeader: () => import("../../main/common/auth_header/auth-header.vue"),
  },
  data() {
    return {
      user: {
        email: null,
        password: null,
      },
      user_detail: {},
      fullPage: false,
      passwordVisible: false,
      slide: 0,
      sliding: null,
      submitted: false,
      firstTime: false,
    };
  },
  mounted() {
    // let externalScript = document.createElement("script");
    // externalScript.setAttribute("src", "https://cdn.firstpromoter.com/fpr.js");
    // // externalScript.setAttribute("cid", "9iau5tg8");
    // document.head.appendChild(externalScript);
  },
  beforeMount() {
    // console.log(
    //   " befor this.$store.getters.getstoreUser;",
    //   this.$store.getters.getstoreUser
    // );
    const fpr = UtilsService.getQueryStringValue("fpr");
    if (fpr && fpr != null && fpr !== undefined) {
      this.$router.push({ name: "signup", query: { fpr: fpr } });
    }
    this.firstTime = localStorage.getItem(FIRST_TIME);
    if (this.firstTime) {
      this.flashMessage.success({
        title: "Success",
        message:
          "Your account is now created, please sign in with your email & password. ",
      });
    }
  },
  validations: {
    user: {
      email: { required, email },
      password: { required },
    },
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    async onSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        loadingService.setloader(true);
        await this.$store
          .dispatch(LOG_IN, this.user)
          .then(async (response) => {
            if (response.jwt) {
              localStorage.setItem(USER_EMAIL, this.user.email);
              loadingService.setloader(true);
              try {
                await this.$store
                  .dispatch(GET_USER_API_ID)
                  .then((data) => {
                    if (data) {
                      localStorage.setItem("$s", data.apiId);
                      loadingService.setloader(false);
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                    loadingService.setloader(false);
                  });
              } catch (error) {
                console.error(error);
              }
              try {
                await this.$store
                  .dispatch(GET_USER_DETAIL, { email: this.user.email })
                  .then((data) => {
                    // if (data) {
                    this.user_detail = data;

                    //   localStorage.setItem(COMPANY_NAME, data.companyName);
                    // }
                    if (data && data.signupCoupon) {
                      localStorage.setItem(SIGN_UP_CODE, data.signupCoupon);
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              } catch (error) {
                console.error("error", error);
              }
              try {
                let apiId = localStorage.getItem("$s");
                await this.$store
                  .dispatch(GET_USER_CREDIT, {
                    apiId: apiId,
                    email: this.user.email,
                  })
                  .then((data) => {
                    if (data && data.currentCredit) {
                      this.user_detail.currentCredit = data.currentCredit;
                      this.user_detail.email = this.user.email;
                      this.$store.dispatch(
                        "setStoreUserData",
                        this.user_detail
                      );
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              } catch (error) {
                console.error(error);
              }
              try {
                this.$store
                  .dispatch(GET_WHATSAPP_PROTO)
                  .then((response) => {
                    console.log("res", response);
                    this.$store.dispatch(
                      "changeWhatsAppPro",
                      response?.data[0]?.whatapp_protocol
                    );
                  })
                  .catch((err) => {
                    loadingService.setloader(false);
                    if (err != undefined) {
                      console.log("error", err);
                    }
                  });
              } catch (error) {
                console.error("error", error);
              }

              loadingService.setloader(false);
              console.log(
                "this.$store.getters.getstoreUser;",
                this.$store.getters.getstoreUser
              );
              const userData = this.$store.getters.getstoreUser;
              analyticsService.userLogin(userData);
              analyticsService.userGroup(userData);
              this.flashMessage.success({
                title: "Success",
                message: "SuccessFully Login",
              });

              if (
                localStorage.getItem(REDIRECT_CODE) &&
                localStorage.getItem(REDIRECT_INTEGRATION_NAME) !== null &&
                this.firstTime == false
              ) {
                this.$router
                  .push({ name: "userIntegration" })
                  .catch((error) => {
                    console.info("error", error.message);
                  });
              } else if (this.firstTime) {
                this.$router.push({ name: "get-started" }).catch((error) => {
                  console.info("error", error.message);
                });
              } else if (!this.firstTime || this.firstTime == null) {
                this.$router.push({ name: "easy-search" }).catch((error) => {
                  console.info("easy-search error", error.message);
                });
              }
            }
          })
          .catch((err) => {
            if (err && err.response && err.response.status) {
              let error = err.response;
              loadingService.setloader(false);
              if (error.status == 500) {
                this.flashMessage.error({
                  title: "Error Message",
                  message: "Invalid email and password",
                });
              } else {
                this.flashMessage.error({
                  title: "Error Message",
                  message:
                    err &&
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                      ? err.response.data.message
                      : "Error",
                });
              }
            }
          });
      }
    },
    getUserApiId() {
      loadingService.setloader(true);
      this.$store
        .dispatch(GET_USER_API_ID)
        .then((data) => {
          if (data) {
            localStorage.setItem("$s", data.apiId);
            loadingService.setloader(false);
          }
        })
        .catch((err) => {
          loadingService.setloader(false);
          console.error(err);
        });
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
};
</script>
<style scoped>
.sign-in-header {
  display: flex;
  justify-content: space-between;
  padding: 15.5px 32px;
}

.heading-login h3 {
  padding-bottom: 16px;
}

.main-body {
  background: #e5e5e5;
  background-repeat: no-repeat;
  height: calc(100vh - 74.8px);
  overflow: auto;
  padding: 64px 15px 15px 15px;
}

/* 
.logo img {
  max-width: 50px;
} */

.login-form {
  height: 616px;
  color: white;
  overflow: hidden;
  max-width: 1126px;
  margin: 0 auto;
  width: 100%;
  box-shadow: 0px 34px 80px rgb(0 0 0 / 7%);
  position: relative;
}

.login-detail {
  background-color: #fff;
  width: 100%;
  z-index: 2;
  height: 616px;
  position: relative;
  padding: 0 15px;
}

.login-detail form {
  max-width: 307px;
  margin: 0 auto;
  padding-top: 72px;
}

.right-bar {
  position: relative;
  display: grid;
  place-content: center;
  height: 100%;
  z-index: 1;
}

.right-bar:before {
  background-color: rgb(255 255 255 / 12%);
  position: absolute;
  width: 200px;
  height: 200px;
  left: 306px;
  top: 102px;
  border-radius: 50%;
  z-index: -1;
  content: "";
}

.carousel {
  width: 480px;
  min-height: 500px;
  height: auto;
}

.bg-shape {
  position: relative;
  background-color: #003172;
}

.bg-shape:before {
  content: "";
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  right: 0;
  left: -13px;
  content: "";
  position: absolute;
  bottom: 0;
  background-position: 100% 100%;
  background-size: contain;
  background-image: url("../../../assets/images/icons/login-bg.png");
}

.slider-main {
  width: 339px;
  background: #fff;
  padding: 49px 32px;
  box-shadow: 0px 34px 80px rgb(0 0 0 / 7%);
  border-radius: 4px;
  text-align: initial;
}

.form-group {
  position: relative;
}

.input-group-append {
  position: absolute;
  top: 33px;
  right: 10px;
}

@media only screen and (max-width: 769px) {
  .slider-main {
    width: 250px;
    padding: 29px 20px;
  }

  .carousel {
    width: 370px;
    min-height: 270px;
    height: auto;
  }
}

@media only screen and (max-width: 576px) {
  .main-body {
    padding: 34px 15px 15px 15px;
  }

  .sign-in-header {
    padding: 15.5px 15px;
  }

  .login-form {
    height: 566px;
  }
}
</style>
